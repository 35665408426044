<template>
    <div class="row justify-content-center">
        <div class="col-md-5">
            <h3 class="text-center">Update</h3>
            <form @submit.prevent="onUpdateForm">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="sr.name" required>
                </div>

                <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="sr.notes">
                </div>
                <div class="form-group">
                    <button class="btn btn-primary btn-block">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';

    export default {
        data() {
            return {
                sr: {
                    name: '',
                    notes: '',
                    done: false,
                },
                originalPers: "",
                serviceid: "",
                service: {},
                currentTenant: "",
            }
        },
        created() {
            this.currentTenant = this.$tenant;
            let dbRef = db.collection('srs').doc(this.$route.params.id);
            dbRef.get().then((doc) => {
                this.sr = doc.data();
                this.originalPers = doc.data().name;
            }).catch((error) => {
                console.log(error)
            })
        },
        methods: {
            onUpdateForm(event) {
                if(this.sr.name != this.originalPers.name) {
                    //Search services for services with name and edit them?
                db.collection('services').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    console.log("OOOOOO"+doc.data().srs.find(x => x.name === this.originalPers))
                    if(doc.data().srs.find(x => x.name === this.originalPers) != null) {
                        if(doc.data().tenant == this.currentTenant) {
                        this.service = doc.data();
                        this.serviceid = doc.id;
                        let entry = { name: this.sr.name, done: this.service.srs[this.service.srs.map((el) => el.name).indexOf(this.originalPers)].done };
                        this.service.srs.splice(this.service.srs.map((el) => el.name).indexOf(this.originalPers), 1)
                        this.service.srs.push(entry);
                        db.collection('services').doc(this.serviceid)
                        .update(this.service).then(() => {
                            console.log("Service successfully updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                    }
                })
                });
                }
                event.preventDefault()
                db.collection('srs').doc(this.$route.params.id)
                .update(this.sr).then(() => {
                    console.log("Updated!");
                    this.$router.push('/srl')
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }
</script>